$andes-theme: 'mercadolibre';
@import '~@andes/common/variables';
@import '~@polycard/phrase/build';
@import '~@polycard/card/build';

.ui-recommendations-list__items-wrapper--grid {
  border-bottom: none;
}

[aria-label*='Súper'],
[aria-label*='Super'] {
  fill: #00a650;
}

.poly-component__shipping [aria-label*='Súper'],
.poly-component__shipping [aria-label*='Super'] {
  transform: translateY(4px);
}

.poly-card--mobile .poly-component__shipping [aria-label*='Súper'],
.poly-card--mobile .poly-component__shipping [aria-label*='Super'] {
  width: 91px;
  height: 18px;
}

.poly-card.poly-card--mobile {
  --poly-general-border-bottom: 1px solid #ededed;
  --poly-general-image-divider: unset;
  // variables available just for list
  --poly-image-size: 110px;

  .poly-component__image-overlay {
    display: none;
  }

  .poly-card__portada--grid-height {
    height: auto;
  }

  &.poly-card--single {
    --poly-padding-card: 16px;
    --poly-padding-content: 0;
    &.poly-card--CORE {
      .poly-card__portada {
        height: fit-content;
      }
    }
  }

  &.poly-card--grid {
    &:nth-of-type(2n + 1) {
      --poly-general-border-right: 1px solid #ededed;
    }
    .poly-card__portada {
      padding: 4px 4px 0;
    }
  }
}

.ui-recommendations-carousel-free--compact {
  .poly-card.poly-card--mobile {
    &.poly-card--grid {
      &:nth-of-type(2n + 1) {
        --poly-general-border-right: unset;
      }
    }
  }
}

.poly-shipping-promise-wrapper:not([class*='mobile']),
.poly-column__rebates {
  .poly-shipping--wrapped,
  .poly-rebates-discount {
    padding: 0 4px;
    line-height: 1.5;
  }
}

.ui-mlt-recos {
  .poly-card.poly-card--mobile {
    .poly-component__image-overlay {
      display: block;
    }
  }
}
